import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
getCompanySettingsAdvanced.operation = 'READ';
export function getCompanySettingsAdvanced() {
    return get(`${API_URL}/company-settings/company-advanced`);
}
setCompanySettingsAdvanced.operation = 'COMMAND';
export function setCompanySettingsAdvanced(companySettingsAdvanced) {
    return put(`${API_URL}/company-settings/company-advanced`, { postData: companySettingsAdvanced });
}
createExportXML.operation = 'COMMAND';
export function createExportXML() {
    return get(`${API_URL}/company-settings/company-advanced/export-xml`);
}
