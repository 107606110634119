import { TrackJS } from 'trackjs';
let trackError = () => { };
let updateConfig = () => { };
const init = () => {
    if (__DIST__ && !/MSIE\s([0-9]|10)\.[0-9]|PingdomTMS|HeadlessChrome/.test(window.navigator.userAgent)) {
        TrackJS.install({
            token: '966260a1f3f84d4aa22069fcbe052278',
            version: __currentRev__,
        });
        updateConfig = currentUser => {
            TrackJS.configure({
                userId: currentUser.id,
            });
            TrackJS.addMetadata('company', currentUser.company.name);
        };
        trackError = TrackJS.track;
        if (window._currentUser) {
            updateConfig(window._currentUser);
        }
    }
    else if (__DEV__) {
        trackError = error => {
            // eslint-disable-next-line no-console
            console.log('[TrackJS] track error', error);
        };
    }
};
export { init, trackError, updateConfig };
