// organize-imports-ignore
import './style.static.less';
import '../base';
import { loadCurrentUser } from './react/services/CurrentUser';
import { loadCompanyDesign } from './design';
(async () => {
    loadCompanyDesign();
    await loadCurrentUser();
    await import(/* webpackChunkName: 'si-app-main', webpackPreload: true */ './index');
})();
