import axios from 'axios';
import axiosRetry from 'axios-retry';
import moment from 'moment';
import { EventType, createEventBusPublisher } from '../react/hooks/useEventBus';
const ENDPOINT = '';
const timezoneOffset = moment().utcOffset().toString();
// @ts-ignore exponentialDelay is not declared properly in the axiosRetry type
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
const publishUnauthorizedResponse = createEventBusPublisher(EventType.UnauthorizedApiResponse);
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    var _a;
    if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
        publishUnauthorizedResponse(error === null || error === void 0 ? void 0 : error.response);
    }
    return Promise.reject(error);
});
export function get(resource, { getData = {}, headers = {} } = {}, urlParams = '') {
    return doRequest(resource, { getData, headers }, 'get', urlParams);
}
export function post(resource, { postData = {}, getData = {}, headers = {} } = {}, urlParams = '') {
    return doRequest(resource, { postData, getData, headers }, 'post', urlParams);
}
export function put(resource, { postData = {}, getData = {}, headers = {} } = {}, urlParams = '') {
    return doRequest(resource, { postData, getData, headers }, 'put', urlParams);
}
export function patch(resource, { postData = {}, getData = {}, headers = {} } = {}, urlParams = '') {
    return doRequest(resource, { postData, getData, headers }, 'patch', urlParams);
}
export function remove(resource, { getData = {}, headers = {} } = {}, urlParams = '') {
    return doRequest(resource, { getData, headers }, 'delete', urlParams);
}
function doRequest(resource, { postData, getData, headers = {} } = {}, verb, urlParams) {
    if (!headers['Content-Type']) {
        headers['Content-Type'] = 'application/json';
    }
    if (!headers['X-Timezone-Offset']) {
        headers['X-Timezone-Offset'] = timezoneOffset;
    }
    switch (verb) {
        case 'get':
            return axios.get(getUrl(resource, urlParams, getData), { headers }).then(r => r.data);
        case 'post':
            return axios.post(getUrl(resource, urlParams, getData), postData, { headers }).then(r => r.data);
        case 'put':
            return axios.put(getUrl(resource, urlParams, getData), postData, { headers }).then(r => r.data);
        case 'patch':
            return axios.patch(getUrl(resource, urlParams, getData), postData, { headers }).then(r => r.data);
        case 'delete':
            // The types for axios are just wrong. Delete does return the payload
            return axios.delete(getUrl(resource, urlParams, getData), { headers }).then(r => r.data);
        default:
            // @ts-ignore This was the original implementation. It's unsound, but somehow still worked?
            return axios[verb](getUrl(resource, urlParams, getData), postData, { headers }).then(r => r.data);
    }
}
function getUrl(resource, urlParams, getData) {
    const getDataFormatted = getData ? formatGetData(getData) : '';
    return withEndpoint([resource, urlParams].filter(x => x.length > 0).join('/') + getDataFormatted);
}
function withEndpoint(url) {
    return ENDPOINT + url;
}
function formatGetData(getData) {
    const keys = Object.keys(getData);
    if (keys.length === 0) {
        return '';
    }
    else {
        return ('?' +
            keys
                .reduce((mem, id) => {
                if (getData[id] !== undefined) {
                    mem.push(id + '=' + getData[id]);
                }
                return mem;
            }, [])
                .join('&'));
    }
}
