export var Plan;
(function (Plan) {
    Plan[Plan["None"] = 0] = "None";
    Plan[Plan["Bronze"] = 1] = "Bronze";
    Plan[Plan["Silver"] = 2] = "Silver";
    Plan[Plan["Gold"] = 3] = "Gold";
    Plan[Plan["Unified"] = 4] = "Unified";
    Plan[Plan["Performance Starter Pack"] = 5] = "Performance Starter Pack";
    Plan[Plan["Feedback and Performance Suite"] = 6] = "Feedback and Performance Suite";
    Plan[Plan["Feedback, Performance and Engagement Suite"] = 7] = "Feedback, Performance and Engagement Suite";
    Plan[Plan["Launch"] = 8] = "Launch";
    Plan[Plan["Grow"] = 9] = "Grow";
    Plan[Plan["Elevate"] = 10] = "Elevate";
})(Plan || (Plan = {}));
export var WarningLevel;
(function (WarningLevel) {
    WarningLevel[WarningLevel["Active"] = 0] = "Active";
    WarningLevel[WarningLevel["Locked"] = 10] = "Locked";
    WarningLevel[WarningLevel["Moved"] = 11] = "Moved";
    WarningLevel[WarningLevel["Maintenance"] = 12] = "Maintenance";
})(WarningLevel || (WarningLevel = {}));
