import Axios from 'axios';
import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
// -- End user facing -- //
const BASE_URL = `${API_URL}/your-billing`;
export function estimate(checkoutRequest) {
    const source = Axios.CancelToken.source();
    const promise = Axios.post(`${BASE_URL}/estimate`, checkoutRequest, {
        cancelToken: source.token,
    }).then(r => r.data, err => {
        if (Axios.isCancel(err)) {
            // Return a Promise that never resolves
            return new Promise(() => { });
        }
        else {
            return Promise.reject(err);
        }
    });
    Object.defineProperty(promise, 'cancel', {
        value: () => {
            source.cancel();
        },
    });
    return promise;
}
export function previewRenewal() {
    return post(`${BASE_URL}/preview-renewal`);
}
setBillingDetails.operation = 'COMMAND';
export function setBillingDetails(billingDetails) {
    return put(`${BASE_URL}/billing-details`, { postData: billingDetails });
}
getCustomer.operation = 'READ';
export function getCustomer() {
    return get(`${BASE_URL}`);
}
checkout.operation = 'COMMAND';
export function checkout(request) {
    return post(`${BASE_URL}/checkout`, { postData: request });
}
checkout.operation = 'COMMAND';
export function createPaymentIntent(amount) {
    return post(`${BASE_URL}/payment-intent`, { postData: amount });
}
removeCard.operation = 'COMMAND';
export function removeCard() {
    return remove(`${BASE_URL}/card`);
}
endSubscription.operation = 'COMMAND';
export function endSubscription(data) {
    return post(`${BASE_URL}/end-subscription`, { postData: data });
}
addBillingContact.operation = 'COMMAND';
export function addBillingContact(billingContact) {
    return post(`${BASE_URL}/billing-contacts`, { postData: billingContact });
}
removeBillingContact.operation = 'COMMAND';
export function removeBillingContact(billingContactId) {
    return remove(`${BASE_URL}/billing-contacts/${billingContactId}`);
}
