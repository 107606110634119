import { API_URL } from '../../../app-config/constants';
import { get, post, put } from '../request';
export function getCalendarIntegrationConfig(companyId) {
    return get(`${API_URL}/admin-calendar-integration`, { getData: { companyId } });
}
export function verifyGoogleConnection() {
    return put(`${API_URL}/admin-calendar-integration/verify-google`);
}
export function removeProvider() {
    return post(`${API_URL}/admin-calendar-integration/remove-provider`);
}
