import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
export function reviewCycleAutocompleteSuggestions(filterBy, reviewCycleId) {
    return get(`${API_URL}/autocomplete-suggestions/review/${reviewCycleId}/${filterBy}`);
}
companyAutocompleteSuggestions.operation = 'READ';
companyAutocompleteSuggestions.byId = true;
export function companyAutocompleteSuggestions(filterBy) {
    return get(`${API_URL}/autocomplete-suggestions/${filterBy}`).then(values => ({ id: filterBy, values }));
}
getAllCompanyAutocompleteSuggestions.operation = 'READ';
export function getAllCompanyAutocompleteSuggestions() {
    return get(`${API_URL}/autocomplete-suggestions`);
}
