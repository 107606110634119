import { get } from 'lodash/fp';
import { updateConfig } from '../../../../app-config/errorTracking';
import api from '../../../api';
const subscribers = [];
let currentUserPromise;
export function loadCurrentUser(redirectToLogin = true) {
    if (currentUserPromise) {
        return currentUserPromise;
    }
    let initialRequest = true;
    currentUserPromise = new Promise((resolve, reject) => {
        api.currentUser.get.createObservable().subscribe(currentUser => {
            updateConfig(currentUser);
            setCurrentUser(currentUser);
            if (initialRequest) {
                initialRequest = false;
                resolve(currentUser);
                return;
            }
            subscribers.forEach(sub => sub(currentUser));
        }, error => {
            if (initialRequest) {
                initialRequest = false;
                if (redirectToLogin && get('response.status', error) === 401) {
                    window.location.replace('/login?continue=' +
                        encodeURIComponent(window.location.href.replace(window.location.origin, '')));
                    return;
                }
                reject(error);
            }
        });
    });
    return currentUserPromise;
}
export function subscribe(subscriber) {
    subscribers.push(subscriber);
    return function unsubscribe() {
        const index = subscribers.indexOf(subscriber);
        if (index === -1) {
            return;
        }
        subscribers.splice(index, 1);
    };
}
export function getCurrentUserId() {
    return window._currentUser.id;
}
export function getCurrentUser() {
    return window._currentUser;
}
function setCurrentUser(currentUser) {
    window._currentUser = currentUser;
}
export function isInExperiment(codeId) {
    const currentUser = getCurrentUser();
    if (!currentUser.experiment) {
        return false;
    }
    return currentUser.experiment.codeId === codeId;
}
export function isInExperimentBranch(codeId, branch) {
    const currentUser = getCurrentUser();
    if (!currentUser.experiment) {
        return false;
    }
    return currentUser.experiment.codeId === codeId && currentUser.experiment.branch === branch;
}
