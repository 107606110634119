import { useEffect } from 'react';
export var EventType;
(function (EventType) {
    EventType[EventType["MeetingCreated"] = 0] = "MeetingCreated";
    EventType[EventType["MeetingBulkUpdate"] = 1] = "MeetingBulkUpdate";
    EventType[EventType["Shake"] = 2] = "Shake";
    EventType[EventType["UnauthorizedApiResponse"] = 3] = "UnauthorizedApiResponse";
})(EventType || (EventType = {}));
const subscribers = [];
function subscribe(type, callback) {
    const listener = { type, callback };
    subscribers.push(listener);
    return () => subscribers.splice(subscribers.indexOf(listener), 1);
}
function publish(type, value) {
    subscribers.filter(listener => listener.type === type).forEach(listener => listener.callback(value));
}
export default function useEventBus(type, callback) {
    useEffect(() => subscribe(type, callback), [type, callback]);
}
// This is not a React Hook, it can be used outside a React component.
export function createEventBusPublisher(type) {
    return (value) => publish(type, value);
}
