export async function uploadFilesSequentially(files, uploadFn) {
    const results = [];
    for (const file of files) {
        results.push(await uploadFn(file));
    }
    return results;
}
export function toFilePayload(file) {
    return {
        fileName: file.file.name,
        mediaType: file.file.type || 'unknown/unknown', // Some files (e.g. *.md) have no type.,
        data: file.data,
    };
}
